<template>
    <div>
        <b-card>
            <Table
                large
                :fields="fields"
                :url="url"
                responsive="lg"
                :title="$t('transaction_currency')"
            >

                <!--:items="transactions"-->
                <!--class="position-relative"-->
                <!--hover-->
                <!--responsive-->
                <!--striped-->

                <template #cell(company_id)="data">
                    <p class="badge badge-light-primary" style="width:55px;" v-if="data.item.company_id">
                        <span>C-{{ data.item.company_id }}</span>
                    </p>
                </template>
                <template #cell(amount)="data">
                    <span>{{ data.item.currency.name }} </span>
                    <span v-if="data.item.amount">{{ data.item.amount }}</span>
                    <span v-else>{{ data.item.crypto_amount }}</span>
                </template>
                <template #cell(exchange_amount)="data">
                    <span>{{ data.item.exchange_currency.name }} </span>
                    <span v-if="data.item.exchange_amount">{{ data.item.exchange_amount }}</span>
                    <span v-else>{{ data.item.exchange_crypto_amount }}</span>

                    <feather-icon v-if="data.item.status == 0" @click="editAction(data.item.id)" class="mx-1" icon="EditIcon"/>

                </template>
                <template #cell(fee_hive_amount)="data">
                    {{data.item.exchange_currency.name}} {{data.item.fee_hive_amount}}
                </template>

                <template #cell(status)="data">
                    <span class="text-warning" v-if="data.item.status == 0"> {{ $t("pending") }} </span>
                    <span class="text-success" v-else-if="data.item.status == 1"> {{ $t("approved") }} </span>
                    <span class="text-danger" v-else-if="data.item.status == 2"> {{ $t("declined") }} </span>
                </template>

                <template #cell(actions)="data">
                    <template v-if="!editMode">
                        <b-button :title="$t('set_completed')" @click="changeStatus(data.item.id, 1)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 0" variant="outline-success">
                            <feather-icon icon="CheckCircleIcon"/>
                        </b-button>
                        <b-button :title="$t('set_closed')" @click="changeStatus(data.item.id, 2)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 0" variant="outline-danger">
                            <feather-icon icon="XIcon"/>
                        </b-button>
                        <b-button :title="$t('set_pending')" @click="changeStatus(data.item.id, 0)" class="mr-1" size="sm" v-b-tooltip.hover v-if="data.item.status == 2" variant="outline-warning">
                            <feather-icon icon="RefreshCwIcon"/>
                        </b-button>
                    </template>
                    <span v-else></span>
                </template>
            </Table>
        </b-card>

        <b-modal
            v-model="editModal"
            size="sm"
            id="modal-center"
            @ok="submitNewAmount()"
        >
            <b-col lg="12" md="12" sm="12" xl="12">
                <b-form-group
                    :label="$t('exchange_amount') + ':'"
                    class="font-weight-bolder"
                    id="exchange_amount"
                    label-for="exchange-amount"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="exchange-amount"
                        rules="required"
                    >
                        <b-form-input
                            type="number"
                            id="exchange-amount"
                            v-model="newExchangeAmount"
                            required
                        ></b-form-input>
                        <!--<small class="text-danger">{{ errors[0] }}</small>-->
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-modal>
    </div>
</template>

<script>

  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import {ValidationProvider, ValidationObserver,} from "vee-validate";
  import Table from "@/components/Common/Table2.vue"


  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      Table
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    data() {
      return {
        url: "/currency/admin",
        totalItems: 0,
        currentPage: 1,
        transactionID: null,
        newExchangeAmount: null,
        editModal: false,
        editMode: false,
        fields: [
          {
            label: this.$t('company'),
            key: "company_id"
          },
          {
            label: this.$t("amount"),
            key: "amount",
          },
          {
            label: this.$t("exchange_amount"),
            key: "exchange_amount",
          },
          {
            label: this.$t("fee_hive_amount"),
            key: "fee_hive_amount",
          },
          {
            label: this.$t("fee_hive_percentage"),
            key: "fee_hive_percentage",
          },
          {
            label: this.$t("status"),
            key: "status",
          },
          {
            label: "actions",
            key: "actions"
          }
        ],
        transactions: null
      }
    },

    created() {
      this.getData();
    },

    methods: {
      editAction(id) {
        this.transactionID = id
        this.editModal = !this.editModal
      },

      submitNewAmount() {
        this.$http.post(`/currency/admin/change-amount/${this.transactionID}`, {amount: this.newExchangeAmount}).then(response => {
          if (response.data.status == 200) {
            this.getData();
          }
        })
      },

      getData() {
        this.$http.post(`/currency/admin?page=${this.currentPage}`).then(response => {

          if (response.data.status == 200) {
            this.transactions = response.data.value.data;
            this.totalItems = response.data.value.total;
            this.currentPage = response.data.value.current_page;
          }

          this.isLoading = false;

        })
      },
      changeStatus(id, status) {
        this.$http.post(`/currency/admin/change-status/${id}/${status}`).then(response => {
          console.log(response);
          if (response.data.status == 200) {
            this.getData();
          } else if (response.data.status == 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(response.data.message),
                icon: "SuccessIcon",
                variant: "danger",
              },
            });
          }

          this.isLoading = false;

        })
      },
    },
  }
</script>

<style scoped>

</style>